.hero {
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width:700px)  {
  .hero {
    height: 300px;
  }
}

@media screen and (max-width:420px)  {
  .hero {
    height: 100px;
  }
}