@import "Variables";

h1 {
  font-size: $copy-size-large;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;

  .logo {
    font-size: 32px;
  }
}

@media screen and (max-width:475px) {
  .header  {
    padding: 20px;
  }
}
