
.intro_inner_wrapper_left {
  width: 43%;
  position: sticky;
  top: -80px;
  float: left;
  padding-top: 100px;

  h1 {
    margin-left: 40px;
    margin-top: 0;
    display: block;
  }
}

.mobile-text {
  display: none !important;
}

.intro_inner_wrapper_right {
  width: 57%;
  float: right;
  padding-top: 100px;


  .intro_spacer_wrapper {
    margin-right: 40px;
    margin-top: 0;
    line-height: 33px;
  }
}


.m-0 {
  margin-top: 0;
}


@media screen and (max-width:1200px) {
  .intro_inner_wrapper_left {
     width: 100%;
     padding-top: 80px;

    h1 {
      margin-bottom: 0;
    }
  }


  .intro_inner_wrapper_right {
    width: auto;
    padding-left: 40px;
    padding-top: 80px;

    .intro_spacer_wrapper {
      margin-right: 40px;
      margin-top: 0;
    }
  }

  .col-right {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width:800px) {
  .intro_inner_wrapper_left {
    h1 {
      font-size: 42px;
      line-height: 56px;
      display: none;
    }
    .mobile-text {
      display: block !important;
    }
  }

  p {
    line-height: 27px;
  }
}

@media screen and (max-width:1200px) {
  .intro_inner_wrapper_left {
    padding-left: 20px;
    position: static;
    h1 {
      font-size: 40px;
      line-height: 48px;
      margin-left: 20px;
    }
  }
}


@media screen and (max-width:600px) {

  .contact_form {
    padding-top: 52px;
  }
  .intro_inner_wrapper_right {
    padding-top: 24px;
    padding-left: 20px;
  }

  .intro_inner_wrapper_left {
    padding-top: 32px;
    position: static;
    padding-left: 0;
    h1 {
      font-size: 40px;
      line-height: 48px;
      margin-left: 20px;
    }
  }

  .col-right {
    margin-left: 20px;
    margin-right: 20px;
  }

  .intro_inner_wrapper_right {

    .intro_spacer_wrapper {
      margin-right: 20px;
      margin-top: 0;
    }
  }
}



