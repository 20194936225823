@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import "src/components/Header";
@import "src/components/Navigation";
@import "src/components/ContactForm";
@import "src/components/Intro";
@import "src/components/Page";
@import "src/components/Text";
@import "src/components/Hero";
@import "src/components/Footer";
@import "src/components/StatCardHeader";

@font-face {
  font-family: 'GT-Alpina-Standard-Light-Italic';
  src:  url('/fonts/GT-Alpina-Standard-Light-Italic.woff2') format('woff2'),
  url('/fonts/GT-Alpina-Standard-Light-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT-Alpina-Standard-Light';
  src:  url('/fonts/GT-Alpina-Standard-Light.woff2') format('woff2'),
  url('/fonts/GT-Alpina-Standard-Light.woff') format('woff');
}





