h1, h5 {
  font-weight: $copy-weight-thin;
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: $h1-text-size;
  line-height: 79px;
  font-family: 'GT-Alpina-Standard-Light', serif;
  color: #49352E;
}

h5 {
  font-size: $h5-text-size;
  color: $secondary-text-color;
  font-weight: 300;
  line-height: 120%;
  font-family: 'GT-Alpina-Standard-Light', serif;
}

/* Main copy styles */
.default-copy {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}

@media screen and (max-width:600px) {
  .default-copy {
    font-size: 18px;
  }

  h1 {
    font-size: 32px !important;
    line-height: 35px !important;
  }

  h5 {
    font-size: $h5-text-size-mobile;
    line-height: $h5-line-height-mobile;
  }
}

.main-content {
  padding-left: 40px;
  padding-bottom: 80px;

  .bold {
    font-weight: 700;
  }

  h3, h2 {
    padding-top: 20px;
  }
}

@media screen and (max-width:475px) {
  .main-content  {
    padding-left: 20px;
  }
}
