.footer {
  background-color: #49352E;
  padding: 160px 40px 30px 40px;

  .footer_link_wrapper {
    margin-bottom: 24px;
  }

  .footer_link {
    margin-right: 40px;
    text-decoration: none;
    font-weight: $copy-weight-thin;
    font-size: $copy-size-default;
    color: $link-text-color;
  }

  .footer_logo_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 44px;
  }

  .footer_divider {
    height: 1px;
    width: 100%;
    background-color: #997867;
  }
}

@media screen and (max-width:950px) {
  .footer_logo_wrapper {
    flex-direction: column;
    align-items: start !important;

    .footer_link {
      margin-top: 40px !important;
    }
  }
}

@media screen and (max-width:600px) {
  .footer {
    padding-top: 80px;
    padding-left: 20px;
  }

  .footer_logo_wrapper {
    .footer_link {
      font-size: 16px;
    }
  }

  .footer_link_wrapper {
    display: flex;
    flex-direction: column;

    .footer_link {
      margin-top: 8px;
      font-size: 16px;
    }
  }
}
