// Primary palette //
$primary-text-color: #49352E;
$secondary-text-color: #141023;
$link-text-color: #F5F4EE;

// Headlines //
$h1-text-size: 72px;
$h5-text-size: 40px;


// mobile headlines //
$h5-text-size-mobile: 24px;
$h5-line-height-mobile: 29px;
$h1-text-size-mobile: 32px;
$h1-line-height-mobile: 35px;


// copy size //
$copy-size-default: 18px;
$copy-size-mobile: 18px;
$copy-size-small: 12px;
$copy-size-large: 20px;

// padding //
$padding-default: 24px;
$padding-small: 8px;
$padding-medium: 16px;
$padding-large: 32px;

// weights //
$copy-weight-default: 400;
$copy-weight-thin: 300;
$copy-weight-medium: 500;
$copy-weight-bold: 700;

// radius //
$radius-default: 8px;
$radius-large: 24px;