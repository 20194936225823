/*
.App {
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

 */

.col-right {
  width: 55%;
  margin-left: auto;
  margin-right: 40px;
}

.form_title_wrapper {
  h5 {
    margin-bottom: 40px;
    margin-top: 80px;
  }
}

.contact_divider {
  height: 1px;
  width: 100%;
  background-color: #85624C;
  opacity: 0.5;
}

.field_wrapper_container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 22px;
}

.w-50 {
  width: 48%;
}

.w-100 {
  width: 100%;
}

.mr-2 {
  margin-right: 40px;
}


.field_wrapper-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#file-upload-button {
  padding: 100px !important;
  background-color: red;
}


.contact_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.artist-radio {
  margin-bottom: 20px !important;
}


.field_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-bottom: 18px;

  label {
    font-size: 18px;
    font-weight: $copy-weight-thin;
    margin-bottom: 8px;
    line-height: 25px;
    color: $primary-text-color;
    position: relative;
  }

  .mandatory {
    position: absolute;
    height: 8px;
    width: 8px;
    padding-left: 1px;
  }

  .symbol {
    color: #997867;
    font-size: 14px;
  }

  .divider {
    padding-top: 8px;
  }

  .subline_label{
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  textarea {
    height: 100px;
    padding: 11.5px 12px;
    background-color: #F5F4EE;
    border-radius: 0;
    font-size: 18px;
    transition: all 0.3s ease;



    &:hover {
      border: 1px solid #49352E;
      transition: all 0.3s ease;

    }

    &:focus {
      outline: none;
      border-width: 1.5px;
    }
  }

  input {
    padding: 11.5px 12px;
    background-color: #F5F4EE;
    border-radius: 0;
    border: 1px solid rgba(73, 53, 46, 0.6);
    font-size: 18px;
    font-family: 'DM Mono', monospace !important;


    &:hover {
      border: 1px solid #49352E;

    }

    &:focus, &:active {
      outline: none;
      border-width: 1.5px;
    }
  }
}

.spacer {
  margin-bottom: 22px;
}

.button {
  padding: 12px 40px;
  border-radius: 8px !important;
  background-color: #333 !important;
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #F5F4ED;
  width: 145px;
  margin-bottom: 60px;
  background: none;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  height: 47px;

  &:hover {
    background-color: #474747 !important;
    border: 1px solid #333333 !important;
    transition: all 0.4s ease;
    cursor: pointer;
  }
}

.success_modal_main_wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #F5F4EE;
  left: 0;
  top: 0;
  z-index: 0;
}

.reminder-section {
  padding-left: 8px;
}

.success_modal {
  background-color: white;
  z-index: 1;
  padding: 30px;
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .button {
    margin-bottom: 0;
  }

  .return {
    text-decoration: none;
    color: #141023;
  }

  h3, p {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 22px;
  }

  .success_logo {
    margin-bottom: 18px;
  }
}


@media screen and (max-width:800px) {
  .w-50 {
    width: 100%;
  }

  .w-100 {
    width: 100%;
  }

  .field_wrapper-row {
    display: block;
  }
}

@media screen and (max-width:600px) {
  label {
    font-size: 16px !important;
    line-height: 22.4px !important;
  }

  .reminder-section {
    padding-left: 0px;
  }

  .radio-section {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }

  .form_title_wrapper {
    h5 {
      margin-bottom: 24px;
      margin-top: 20px;
    }
  }

  .spacer {
    margin-bottom: 0;
  }

  .button {
    width: 100%;
  }

  .custom-file-input::before {
    display: none !important;
    text-align: center;
    margin-bottom: 20px;
  }

  .field_wrapper {
    margin-top: 40px;
  }

  .field_wrapper-row {
    margin-top: -18px;
  }
}



.custom-file-input {
  width: -webkit-fill-available !important;

  &.two {
    margin-top: 10px;
  }
}


.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;

}
.custom-file-input::before {
  content: 'Select a file';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 15px 12px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 12pt;
  color: #49352E;

}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.link {
  text-decoration: none;
  font-weight: 700;
  color: inherit;

  &:hover {
    color: #49355E;
  }
}


/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 8px !important;
  margin-top: 16px !important;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:nth-of-type(2) {
    margin-top: 8px !important;
  }

}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  border: 1px solid #49352E;
}


.container input:checked ~ .checkmark {
  background: #49352E;
  border-radius: 100%;
  transition: all 0.3s ease;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
  transition: all 0.3s ease;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 4px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 2px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
